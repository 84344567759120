import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Header from '@components/Header';
import Footer from '@components/Footer';
import FeaturedPost from '@components/FeaturedPost';
import ReadingArticle from '@components/theme/ReadingArticle';
import SharePage from '@components/theme/SharePage';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import BackgroundImage from 'gatsby-background-image';
import GatsbyImage from 'gatsby-image';
import { find } from 'lodash';

const ImageCss = css`
  position: relative;
  overflow: hidden;
  border-radius: 170px;
  margin-right: 2rem;
  min-height: 120px;
  min-width: 120px;
`;

export default function Post({ data: { post }, pageContext: { language, refs, layouts, configuration, meta }}) {
  return (
    <Fragment>
      <FeaturedPost
        featured={post}
        language={language}
      >
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </FeaturedPost>
      <ReadingArticle body={post.blogPost.body}>
        <SharePage meta={meta} post={post} configuration={configuration} />
        {post.blogPost.author &&
          <Fragment>
            <hr />
            <div className="media">
              <GatsbyImage
                css={ImageCss}
                fluid={post.blogPost.author.teamMember.photo.localFile.sharp.fluid}
              />
              <div className="media-body">
                <h5 className="mt-0">{post.blogPost.author.teamMember.name}</h5>
                {post.blogPost.author.teamMember.description && (
                  <div dangerouslySetInnerHTML={{ __html: post.blogPost.author.teamMember.description }} />
                )}
              </div>
            </div>
          </Fragment>
        }
      </ReadingArticle>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Fragment>
  );
}

export const query = graphql`
  query($id: String) {
    post: wpBlogPost(id: { eq: $id }) {
      seo {
        focuskw
      }
      properties {
        slug
        title
        featuredImage {
          localFile {
            sharp: childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      blogPost {
        author {
          ... on WpTeamMember {
            id
            teamMember {
              description
              jobTitle
              name
              photo {
                localFile {
                  sharp: childImageSharp {
                    fluid(maxWidth: 170, maxHeight: 170) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        body
        publishDate
      }
    }
  }
`;